<script setup lang="ts">
import { login } from "~/instances/KeurtApiClient";
import type { User } from "../models/User";
import { AccessType } from "~/enums/accesstypes";
import InputText from "primevue/inputtext";

const email = ref();
const password = ref();
const submitted = ref<boolean>(false);
const loginSucces = ref<boolean>(true);
const emailErrorMessage = ref<string>();
const passwordErrorMessage = ref<string>();

const tryLogin = async() => {
  submitted.value = true;
  if(!email.value) {
    emailErrorMessage.value = "verplicht veld";
    return;
  }
  if(!password.value) {
    passwordErrorMessage.value = "verplicht veld"
    return;
  }
  if(!validateEmail(email.value)){
    emailErrorMessage.value = "E-mailadres is niet correct"
  }
  loginSucces.value = await login(email.value, password.value);
  if (loginSucces.value) {
    navigateBasedOnAccesstype();
  } else {
    passwordErrorMessage.value = "Wachtwoord incorrect"
    return;
  }
}

const validateEmail = (email:string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


const navigateBasedOnAccesstype = () => {
  const userData = sessionStorage.getItem("user");
  if (userData) {
    const user:User = JSON.parse(userData);
    switch(user.accessType){
      case AccessType.Admin:
      case AccessType.Keurmeister:
        navigateTo("/");
        break;
      case AccessType.CustUser:
      case AccessType.UserAdmin:
        console.log("?");
        navigateTo("/locations");
        break;
    }
  }
  else{
    return navigateTo("/login");
  }
}
</script>

<template>
  <div class="grid min-h-screen place-content-center bg-[#F5F7FA]">
    <div class="flex flex-col justify-content w-80">
      <div class="bg-keurt-logo bg-no-repeat bg-center flex flex-fill h-40" />
      <div class="flex flex-col">
        <label
          for="email"
          class="text-sm text-[#556D85]"
        >E-mailadres</label>
        <InputText
          id="email"
          v-model="email"
          type="email"
          name="email"
          class="rounded-full"
          :class="{'p-invalid': submitted && (!email || !validateEmail(email))}"
          placeholder="gebruiker@domein.nl"
          @keyup.enter="tryLogin()"
        />
        <small
          v-if="submitted && (!email || !validateEmail(email))"
          class="p-error"
        >{{ emailErrorMessage }}</small>
      </div>
      <div class="flex flex-col mt-9 mb-6">
        <label
          for="password"
          class="text-sm text-[#556D85]"
        >Wachtwoord</label>
        <InputText
          id="password"
          v-model="password"
          type="password"
          name="password"
          :class="{'p-invalid': submitted && (!password || !loginSucces)}"
          class="rounded-full"
          @keyup.enter="tryLogin()"
        />
        <small
          v-if="submitted && (!password || !loginSucces)"
          class="p-error"
        >{{ passwordErrorMessage }}</small>
      </div>

      <Button
        class="w-80"
        label="Inloggen"
        rounded
        @click="tryLogin()"
      />
    </div>
    <a class="flex justify-evenly mt-4" href="/requestPasswordReset"><p class="text-[#FF6828]">Wachtwoord vergeten?</p></a>
  </div>
</template>
